import axios from 'axios';
import * as Constants from './constants.js';

export async function loadCountries(caller) {
	await axios.post(Constants.LOOKUP_ENDPOINT+'/CountryList/get.action',{filter:""},{headers: {Authorization: Constants.API_AUTH_CONFIG}})
    .then((response) => {
   	 caller(response.data); 
   })
}

export async function loadStates(caller,country) {
	await axios.post(Constants.LOOKUP_ENDPOINT+'/StateProvinceCountyList/get.action',{filter:"where countrycode = '"+country+"'"},{headers: {Authorization: Constants.API_AUTH_CONFIG}})
    .then((response) => {
   	 caller(response.data); 
   })
}

export async function authenticateUser(email,password) {
	await axios.post(Constants.USER_ENDPOINT+'/login',{loginEmail:email, loginPassword:password},{headers: {Authorization: Constants.API_AUTH_CONFIG}})
    .then((response) => {
        console.log(response.data);
        sessionStorage.setItem(Constants.SESSIONID,response.data.sessionid?response.data.sessionid:'');
        sessionStorage.setItem(Constants.SESSIONEMAIL,response.data.userprincipal?response.data.userprincipal.loginemail:'');
        sessionStorage.setItem(Constants.SESSIONUSER,response.data.userprincipal?response.data.userprincipal.firstname + " " + response.data.userprincipal.lastname:'');
        sessionStorage.setItem(Constants.SESSIONROLES,response.data.userprincipal?response.data.userprincipal.roles:'');
   	 if(response.data.status !== true) {
        throw "Either Email or Password does not exists.";
      }
   })
}

export async function validateSession(email,sessionid) {
	await axios.post(Constants.USER_ENDPOINT+'/session',{loginEmail:email, sessionId:sessionid},{headers: {Authorization: Constants.API_AUTH_CONFIG}})
    .then((response) => {
        console.log(response.data);
        sessionStorage.setItem(Constants.SESSIONID,response.data.sessionid?response.data.sessionid:'');
        sessionStorage.setItem(Constants.SESSIONEMAIL,response.data.userprincipal?response.data.userprincipal.loginemail:'');
        sessionStorage.setItem(Constants.SESSIONUSER,response.data.userprincipal?response.data.userprincipal.firstname + " " + response.data.userprincipal.lastname:'');
        sessionStorage.setItem(Constants.SESSIONROLES,response.data.userprincipal?response.data.userprincipal.roles:'');
   	 if(response.data.status !== true) {
        throw {name : "AuthenticationError", message : "No current user"};
      }
   })
}

export async function deleteSession(sessionid) {
	await axios.post(Constants.USER_ENDPOINT+'/session/delete',{loginEmail:'', sessionId:sessionid},{headers: {Authorization: Constants.API_AUTH_CONFIG}})
    .then((response) => {
        console.log(response.data);
        sessionStorage.setItem(Constants.SESSIONID,'');
        sessionStorage.setItem(Constants.SESSIONEMAIL,'');
        sessionStorage.setItem(Constants.SESSIONUSER,'');
        sessionStorage.setItem(Constants.SESSIONROLES,'');
   })
}

export async function modifyPassword(email,currentpassword, newpassword, confirmpassword) {
	await axios.post(Constants.USER_ENDPOINT+'/modify/password',{loginemail:email, currentpassword:currentpassword, newpassword:newpassword, confirmpassword:confirmpassword},{headers: {Authorization: Constants.API_AUTH_CONFIG}})
    .then((response) => {
        console.log(response.data);
        if(response.data.status !== true) {
            throw {name : "ModificationError", message : response.data.message};
        }
   })
}


//axios.post(Constants.LOOKUP_ENDPOINT+'/CountryList/get.action',{filter:"where name like '%"+this.state.ddlsearch+"%' ", nameProp:Constants.LU_NAME, valueProp:Constants.LU_CODE},{headers: {Authorization: Constants.API_AUTH_CONFIG}})
//.then((response) => {
//	 this.setState({countries: response.data}); 
////	 value.loading = false;
//	 this.setState({ddlsearch: ''});
//
////	 console.log(this.state.ddlsearch);
////	 console.log(Constants.API_AUTH_CONFIG);
//})