import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import * as Constants from '../irscripts/constants.js';
import * as IRCall from '../irscripts/ircalls.js';
import "../css/Settings.css";

export default function Settings(props){

  async function onLoad() {
    try {
      var sessionID = sessionStorage.getItem(Constants.SESSIONID);
      var loginEmail = sessionStorage.getItem(Constants.SESSIONEMAIL);

      props.setLoggedinUser(sessionStorage.getItem(Constants.SESSIONUSER));
      props.setUserRoles(sessionStorage.getItem(Constants.SESSIONROLES))

      console.log("Session:" +sessionID);
      if(sessionID === "") {
        throw {name : "AuthenticationError", message : "No current user"};
      }
        await IRCall.validateSession(loginEmail,sessionID);
        props.userHasAuthenticated(true);
    }
    catch(e) {
      if (e.message === 'No current user') {
      props.userHasAuthenticated(false);
      }
    }
    props.setIsAuthenticating(false);
  }
  
    return (
      <div className="Settings">
        <LinkContainer to="/settings/email">
          <LoaderButton
            block
            bsSize="large"
            text="Change Email"
          >Change Email</LoaderButton>
        </LinkContainer>
        <LinkContainer to="/settings/password">
          <LoaderButton
            block
            bsSize="large"
            text="Change Password"
          >Change Password</LoaderButton>
        </LinkContainer>
      </div>
    );
}