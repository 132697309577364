import React, {useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Tabs, Tab, FormGroup, ControlLabel, FormControl} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import * as Constants from '../irscripts/constants.js';
import * as IRCall from '../irscripts/ircalls.js';
import "../css/UserProfile.css";
import "../css/global.css";

export default function UserProfile(props) {

    const [isLoading, setIsLoading] = useState(false);

    const [profileFields, handleProfileFieldChange] = useFormFields({
        loginEmail: "",
        loginPassword: ""
    });

    const [passwordFields, handlePasswordFieldChange] = useFormFields({
        loginEmail: "",
        currentPassword: "",
        newPassword:"",
        confirmPassword:""
    });
    
    const [emailFields, handleEmailFieldChange] = useFormFields({
        loginEmail: "",
        loginPassword: ""
    });

    async function onLoad() {
    try {
        var sessionID = sessionStorage.getItem(Constants.SESSIONID);
        var loginEmail = sessionStorage.getItem(Constants.SESSIONEMAIL);

        props.setLoggedinUser(sessionStorage.getItem(Constants.SESSIONUSER));
        props.setUserRoles(sessionStorage.getItem(Constants.SESSIONROLES))

        console.log("Session:" +sessionID);
        if(sessionID === "") {
        throw {name : "AuthenticationError", message : "No current user"};
        }
        await IRCall.validateSession(loginEmail,sessionID);
        props.userHasAuthenticated(true);
    }
    catch(e) {
        if (e.message === 'No current user') {
        props.userHasAuthenticated(false);
        }
    }
    props.setIsAuthenticating(false);
    }

    function validateForm() {
    return true;
    }

      
    async function handleProfileSave(event) {
    event.preventDefault();
    setIsLoading(true);

    setIsLoading(false);
    }

    async function handlePasswordSave(event) {
        try{
            event.preventDefault();
            setIsLoading(true);
            var loginEmail = sessionStorage.getItem(Constants.SESSIONEMAIL);
            await IRCall.modifyPassword(loginEmail,passwordFields.currentPassword, passwordFields.newPassword, passwordFields.confirmPassword);
            setIsLoading(false);
            alert("Password Modified");
        }catch(e) {
            setIsLoading(false);
            alert(e.message);
        }
    }

    async function handleEmailSave(event) {
    event.preventDefault();
    setIsLoading(true);

    setIsLoading(false);
    }

    return (
    <Tabs defaultActiveKey={1} id="uncontrolled-tab-example" onSelect = {onLoad}>
        <Tab eventKey={1} title="Edit Profile">
        <div className="UserProfile">
            <form onSubmit={handleProfileSave}>
                <FormGroup controlId="loginEmail" bsSize="small">
                <ControlLabel>Email</ControlLabel>
                <FormControl
                    autoFocus
                    type="email"
                    value={profileFields.loginEmail}
                    onChange={handleProfileFieldChange}
                />
                </FormGroup>
                <FormGroup controlId="loginPassword" bsSize="small">
                <ControlLabel>Password</ControlLabel>
                <FormControl
                    value={profileFields.loginPassword}
                    onChange={handleProfileFieldChange}
                    type="password"
                />
                </FormGroup>
                <LoaderButton
                block
                bsStyle="primary"
                type="submit"
                bsSize="large"
                isLoading={isLoading}
                disabled={!validateForm()}
                >
                Save
                </LoaderButton>
            </form>
            </div>
        </Tab>
        <Tab eventKey={2} title="Change Password">
        <div className="UserProfile">
        <form onSubmit={handlePasswordSave}>
                <FormGroup controlId="currentPassword" bsSize="small">
                <ControlLabel>Current Password</ControlLabel>
                <FormControl
                    autoFocus
                    type="password"
                    value={passwordFields.currentPassword}
                    onChange={handlePasswordFieldChange}
                />
                </FormGroup>
                <FormGroup controlId="newPassword" bsSize="small">
                <ControlLabel>New Password</ControlLabel>
                <FormControl
                    value={passwordFields.newPassword}
                    onChange={handlePasswordFieldChange}
                    type="password"
                />
                </FormGroup>
                <FormGroup controlId="confirmPassword" bsSize="small">
                <ControlLabel>Confirm Password</ControlLabel>
                <FormControl
                    value={passwordFields.confirmPassword}
                    onChange={handlePasswordFieldChange}
                    type="password"
                />
                </FormGroup>
                <LoaderButton
                block
                bsStyle="primary"
                type="submit"
                bsSize="large"
                isLoading={isLoading}
                disabled={!validateForm()}
                >
                <span className = "ButtonIcon">
                <img
                    alt=""
                    src={require("../images/icons/save-file-btn.png")}
                    width="16"
                    height="16"
                />
                </span>
                <span>Save</span>
                </LoaderButton>
            </form>
            </div>
        </Tab>
        <Tab eventKey={3} title="Change Email">
        <div className="UserProfile">
            <form onSubmit={handleEmailSave}>
                <FormGroup controlId="loginEmail" bsSize="small">
                    <ControlLabel>Email</ControlLabel>
                    <FormControl
                        autoFocus
                        type="email"
                        value={emailFields.loginEmail}
                        onChange={handleEmailFieldChange}
                    />
                    </FormGroup>
                    <FormGroup controlId="loginPassword" bsSize="small">
                    <ControlLabel>Password</ControlLabel>
                    <FormControl
                        value={emailFields.loginPassword}
                        onChange={handleEmailFieldChange}
                        type="password"
                    />
                </FormGroup>
                <LoaderButton
                    block
                    bsStyle="primary"
                    type="submit"
                    bsSize="large"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Save
                </LoaderButton>
            </form>
            </div>
        </Tab>
    </Tabs>
    );
}