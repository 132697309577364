import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as IRCall from '../irscripts/ircalls.js';
import { FormGroup, FormControl, ControlLabel,Alert, Button } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "../css/Login.css";
import * as Constants from '../irscripts/constants.js';

export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    loginEmail: "",
    loginPassword: ""
  });

  function validateForm() {
    return fields.loginEmail.length > 0 && fields.loginPassword.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await IRCall.authenticateUser(fields.loginEmail, fields.loginPassword);
      //alert("Logged in as User: " + fields.loginEmail);
      props.setLoggedinUser(sessionStorage.getItem(Constants.SESSIONUSER));
			props.setUserRoles(sessionStorage.getItem(Constants.SESSIONROLES))
      props.userHasAuthenticated(true);
    } catch (e) {
      //alert(e);
      setShow(true);
      setIsLoading(false);
    }
  }

  function handleDismiss() {
    setShow(false);
  }

  function LoginAlert() {
    if (show) {
      return (
        <Alert bsStyle="danger" onClose={() => setShow(false)} dismissible = "true">
          <table style={{width:"100%"}}>
          <tbody>
          <tr>
            <td>
            <img
								alt=""
								src={require("../images/icons/cross-mark-on-a-black-circle-background.png")}
								width="32"
								height="32"
							/>
            </td>
            <td>
              Authentication Failed: Email or Password does not match.
            </td>
            <td style = {{textAlign:"right"}}>
            <Button onClick={handleDismiss}>Hide Alert</Button>
            </td>
          </tr>
          </tbody>
          </table>
        </Alert>
      );
    } else
    return <div></div>;
  }

  return (
    <div className="Login">
      <LoginAlert/>
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="loginEmail" bsSize="small">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.loginEmail}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="loginPassword" bsSize="small">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={fields.loginPassword}
            onChange={handleFieldChange}
            type="password"
          />
        </FormGroup>
        <Link to="/login/reset">Forgot password?</Link>
        <LoaderButton
          block
          bsStyle="primary"
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </form>
    </div>
  );
}