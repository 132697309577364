import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem, NavDropdown, Menu, MenuItem} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import * as IRCall from './irscripts/ircalls.js';
import * as Constants from './irscripts/constants.js';
import "./css/App.css";
import Routes from "./Routes";

function App(props) {
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [isAuthenticated, userHasAuthenticated] = useState(false);
	const [getLoggedinUser, setLoggedinUser] = useState("");
	const [getUserRoles, setUserRoles] = useState("");

	useEffect(() => {
		onLoad();
	}, []);

	async function onLoad() {
		try {
			var sessionID = sessionStorage.getItem(Constants.SESSIONID);
			var loginEmail = sessionStorage.getItem(Constants.SESSIONEMAIL);
			
			setLoggedinUser(sessionStorage.getItem(Constants.SESSIONUSER));
			setUserRoles(sessionStorage.getItem(Constants.SESSIONROLES))

			console.log("Session:" +sessionID);
			if(sessionID === "") {
				throw {name : "AuthenticationError", message : "No current user"};
			}
			await IRCall.validateSession(loginEmail,sessionID);
		  	userHasAuthenticated(true);
		}
		catch(e) {
		  if (e.message === 'No current user') {
			userHasAuthenticated(false);
			//alert(e.message);
			//handleLogout();
		  }
		}
		setIsAuthenticating(false);
	}

	async function handleLogout() {
		userHasAuthenticated(false);
		var sessionID = sessionStorage.getItem(Constants.SESSIONID);
		if(sessionID !== "") {
			await IRCall.deleteSession(sessionID);
		}
		props.history.push("/login");
	}

	return (
		!isAuthenticating &&
	  	<div className="App container">
			<Navbar fluid collapseOnSelect onSelect={onLoad}>
				<Navbar.Header>
					<Navbar.Brand>
						<Link to="/">
							<img
								alt=""
								src={require("./images/icons/home.png")}
								width="20"
								height="20"
							/>
						</Link>
					</Navbar.Brand>
					<Navbar.Toggle />
				</Navbar.Header>
				<Navbar.Collapse>
					{isAuthenticated ? (
					<>
					<Nav>
						<NavDropdown title = "Purchasing" id="basic-nav-dropdown">
							<LinkContainer to="/supplier">
								<NavItem>
								<img
								alt=""
								src={require("./images/icons/manufacture.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className= "imgmenutext">Supplier</span>
									</NavItem>
							</LinkContainer>
							<MenuItem divider />
							<LinkContainer to="/inventory">
								<NavItem>
								<img
								alt=""
								src={require("./images/icons/inventory.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className= "imgmenutext">Inventory</span>
									</NavItem>
							</LinkContainer>
							<MenuItem divider />
							<LinkContainer to="/purchase">
							<NavItem><img
								alt=""
								src={require("./images/icons/sign-in.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className="imgmenutext">Purchase Order</span></NavItem>
							</LinkContainer>
						</NavDropdown>
						<NavDropdown title = "Sales" id="basic-nav-dropdown">
							<LinkContainer to="/customer">
								<NavItem>
								<img
								alt=""
								src={require("./images/icons/shop.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className= "imgmenutext">Customer</span>
									</NavItem>
							</LinkContainer>
							<MenuItem divider />
							<LinkContainer to="/invoice">
							<NavItem><img
								alt=""
								src={require("./images/icons/invoice.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className="imgmenutext">Invoice</span></NavItem>
							</LinkContainer>
							<MenuItem divider />
							<LinkContainer to="/creditmemo">
							<NavItem><img
								alt=""
								src={require("./images/icons/voucher.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className="imgmenutext">Credit Memo</span></NavItem>
							</LinkContainer>
							<MenuItem divider />
							<LinkContainer to="/delivery">
							<NavItem><img
								alt=""
								src={require("./images/icons/delivery-truck-silhouette.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className="imgmenutext">Delivery</span></NavItem>
							</LinkContainer>
						</NavDropdown>
						<NavDropdown title = "Marketing" id="basic-nav-dropdown">
							<LinkContainer to="/homewebsite">
								<NavItem>
								<img
								alt=""
								src={require("./images/icons/sitemap.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className= "imgmenutext">Profile Website</span>
									</NavItem>
							</LinkContainer>
							<MenuItem divider />
							<LinkContainer to="/shopwebsite">
							<NavItem><img
								alt=""
								src={require("./images/icons/commerce.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className="imgmenutext">Shopping Website</span></NavItem>
							</LinkContainer>
						</NavDropdown>
						<NavDropdown title = "Reports" id="basic-nav-dropdown">
							<LinkContainer to="/report1">
								<NavItem>
								<img
								alt=""
								src={require("./images/icons/settings.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className= "imgmenutext">Report 1</span>
									</NavItem>
							</LinkContainer>
							<MenuItem divider />
							<LinkContainer to="/report2">
							<NavItem><img
								alt=""
								src={require("./images/icons/power-button-off.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className="imgmenutext">Report 2</span></NavItem>
							</LinkContainer>
						</NavDropdown>
					</Nav>
					<Nav pullRight>
						<LinkContainer to="/newinventory">
								<NavItem>
								<img
							alt="New Inventory"
							title = "New Inventory"
							src={require("./images/icons/inventory.png")}
							width="16"
							height="16"
							className="imgmenu"
						/>
									</NavItem>
						</LinkContainer>
						<LinkContainer to="/newpo">
								<NavItem>
								<img
							alt="New Purchase Order"
							title = "New Purchase Order"
							src={require("./images/icons/sign-in.png")}
							width="16"
							height="16"
							className="imgmenu"
						/>
									</NavItem>
						</LinkContainer>
						<LinkContainer to="/newinvoice">
								<NavItem>
								<img
							alt="New Invoice"
							title = "New Invoice"
							src={require("./images/icons/invoice.png")}
							width="16"
							height="16"
							className="imgmenu"
						/>
									</NavItem>
						</LinkContainer>
						<NavDropdown title = {getLoggedinUser} id="basic-nav-dropdown">
							<LinkContainer to="/profile">
								<NavItem>
								<img
								alt=""
								src={require("./images/icons/group-profile-users.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className= "imgmenutext">Edit Profile</span>
									</NavItem>
							</LinkContainer>
							<MenuItem divider />
							<LinkContainer to="/settings">
								<NavItem>
								<img
								alt=""
								src={require("./images/icons/cog-wheel-silhouette.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className= "imgmenutext">Account</span>
									</NavItem>
							</LinkContainer>
							<MenuItem divider />
							<NavItem onClick={handleLogout}><img
								alt=""
								src={require("./images/icons/power-button-off.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className="imgmenutext">Logout</span></NavItem>
						</NavDropdown>
						</Nav>
					</>
					) : (
					<>
					<Nav pullRight="true">
						<LinkContainer to="/signup">
							<NavItem><img
								alt=""
								src={require("./images/icons/file.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className="imgmenutext">Signup</span></NavItem>
						</LinkContainer>
						<LinkContainer to="/login">
							<NavItem><img
								alt=""
								src={require("./images/icons/user-shape.png")}
								width="16"
								height="16"
								className="imgmenu"
							/><span className="imgmenutext">Login</span></NavItem>
						</LinkContainer>
						</Nav>
					</>
					)}
					
				</Navbar.Collapse>
			</Navbar>
			<Routes appProps={{ isAuthenticating, setIsAuthenticating, isAuthenticated, userHasAuthenticated, getLoggedinUser, setLoggedinUser, getUserRoles, setUserRoles }} />
	  	</div>
	);
  }

  export default withRouter(App);