export const API_HOST = "http://api.invoiceready.com.au/";
export const API_AUTHUSER = "invoicereadyapi";
export const API_AUTHPASS = "$2a$10$sUkNcgJnGx9Kj/QvkFJxT.z5Y2CVGY0Yh9UlOYPfOCBsud/NwvDhG";
export const API_AUTH_CONFIG = "Basic "+window.btoa(API_AUTHUSER + ':' + API_AUTHPASS);
export const LOOKUP_ENDPOINT = API_HOST + "/ir/api/lookup";
export const USER_ENDPOINT = API_HOST + "/ir/api/user";
export const SESSIONID = "SESSID";
export const SESSIONEMAIL = "EMAIL";
export const SESSIONUSER = "USER";
export const SESSIONROLES = "ROLES";

// export const LU_NAME = "name";
// export const LU_CODE = "code";

export const reqHeader = "{headers: { authorization: 'Basic dGVzdDp0ZXN0'}}";
export const mountNode = document.getElementById("root");

