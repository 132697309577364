import React, { useState } from "react";
import * as UI from 'semantic-ui-react'
import * as IRCall from '../irscripts/ircalls.js';
import 'semantic-ui-css/semantic.min.css'

import {
  HelpBlock,
  FormGroup,
  Row,
  Col,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "../css/Signup.css";

export default function Signup(props) {
  const [fields, handleFieldChange] = useFormFields({
    firstName:"",
    lastName:"",
    loginEmail: "",
    loginPassword: "",
    confirmPassword: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    citySuburbCode:"",
    citySuburb:"",
    stateCountyCode:"",
    countryCode:"",
    postalCode:"",
    confirmationCode: ""
  });
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [getCountries, setCountries] = useState(null);
  const [getStates, setStates] = useState(null);
  
  function validateForm() {
    return (
      fields.loginEmail.length > 0 &&
      fields.loginPassword.length > 0 &&
      fields.loginPassword === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    setNewUser("test");

    setIsLoading(false);
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
  }

  const loadCountryDDL = (e, value) => {
	  if(!value.options || value.options.length === 0) {
		  IRCall.loadCountries(setCountries);
	  }
  }
  
  const loadStateDDL = (e, value) => {
    fields.countryCode = value.value;
		IRCall.loadStates(setStates, value.value);
  }

  const setStateCode = (e, value) => {
    fields.stateCountyCode = value.value;
  }
  
  const onSearchChange = (e, value) => {
	  this.setState({ddlsearch: value.searchQuery}); 
	  console.log(this.state.ddlsearch);
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="small">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <HelpBlock>Please check your email for the code.</HelpBlock>
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="small"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </form>
    );
  }

  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="firstName" bsSize="small">
          <ControlLabel>First Name</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={fields.firstName}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="lastName" bsSize="small">
          <ControlLabel>Last Name</ControlLabel>
          <FormControl
            type="text"
            value={fields.lastName}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="loginEmail" bsSize="small">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            type="email"
            value={fields.loginEmail}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="loginPassword" bsSize="small">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            type="password"
            value={fields.loginPassword}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="small">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </FormGroup>
        <FormGroup controlId="countryCode" bsSize="small">
          <ControlLabel>Country</ControlLabel>
            <UI.Dropdown
              placeholder='Select Country'
              fluid
              search
              selection
              options={getCountries}
              onFocus={loadCountryDDL}
              onChange={(param, data) => loadStateDDL(param,data)}
              value={fields.countryCode}
            />
        </FormGroup>
        <FormGroup controlId="stateCountyCode" bsSize="small">
          <ControlLabel>State</ControlLabel>
            <UI.Dropdown
              placeholder='Select State'
              fluid
              search
              selection
              options={getStates}
              onChange={(param, data) => setStateCode(param,data)}
              onChange={handleFieldChange}
            />
        </FormGroup>
        <FormGroup controlId="citySuburbCode" bsSize="small">
          <ControlLabel>Suburb / City</ControlLabel>
          <FormControl
            type="text"
            value={fields.citySuburbCode}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="addressLine1" bsSize="small">
          <ControlLabel>Street Number</ControlLabel>
          <FormControl
            type="text"
            value={fields.addressLine1}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="addressLine2" bsSize="small">
          <ControlLabel>Street Name</ControlLabel>
          <FormControl
            type="text"
            value={fields.addressLine2}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="addressLine3" bsSize="small">
          <FormControl
            type="text"
            value={fields.addressLine3}
            onChange={handleFieldChange}
          />
        </FormGroup>
        
        <LoaderButton
          block
          className="btn-primary"
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Signup
        </LoaderButton>
      </form>
    );
  }

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
      <div className="footer"></div>
    </div>
    
  );
}