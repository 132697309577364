import React, {useEffect} from "react";
import * as Constants from '../irscripts/constants.js';
import * as IRCall from '../irscripts/ircalls.js';
import "../css/Home.css";

export default function Home(props) {
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      var sessionID = sessionStorage.getItem(Constants.SESSIONID);
      var loginEmail = sessionStorage.getItem(Constants.SESSIONEMAIL);

      props.setLoggedinUser(sessionStorage.getItem(Constants.SESSIONUSER));
      props.setUserRoles(sessionStorage.getItem(Constants.SESSIONROLES))

      console.log("Session:" +sessionID);
      if(sessionID === "") {
        throw {name : "AuthenticationError", message : "No current user"};
      }
        await IRCall.validateSession(loginEmail,sessionID);
        props.userHasAuthenticated(true);
    }
    catch(e) {
      if (e.message === 'No current user') {
        props.userHasAuthenticated(false);
      }
    }
    props.setIsAuthenticating(false);
  }
  return (
    <div className="Home">
      <div className="lander">
        <img className="logoimage" alt = "irlogo" src={require("../images/ir-logo.png")} />
        <h1>InvoiceReady@AU</h1>
        <p>A complete sales, marketing and invoicing solution</p>
      </div>
      <div className="footer">
        <p>Powered by Impulsesoft</p>
      </div>
    </div>
  );
}